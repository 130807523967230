<div class="fixed h-screen w-screen bg-black/60 z-40 top-0 left-0 bg-anim" *ngIf="opened"></div>

<div class="flex fixed z-50 top-0 left-0 h-screen w-screen items-center justify-center" #dialogMain *ngIf="parent_open">
  <div [ngClass]="{ 'md:rounded-lg md:m-4 h-screen md:h-auto md:max-h-[90vh]': scaler, 'rounded-lg m-4 max-h-[90vh]': !scaler }"
    class="bg-white dark:bg-gray-800 max-w-full overflow-y-auto"
    [style.width]="width || '500px'" [@openClose] *ngIf="opened">
    <div [class.p-6]="padding">
      <div *ngIf="scaler" class="relative block md:hidden mb-10">
        <a (click)="this.dialog.close()"
          class="rounded-full h-8 w-8 inline-grid place-content-center cursor-pointer text-2xl text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-900 absolute z-50">
          <i class="fa-solid fa-xmark"></i>
        </a>
      </div>
      <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
    </div>
  </div>
</div>
