import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
// import LogRocket from 'logrocket';
import { AuthService } from './shared/auth.service';

declare var Paddle: any;

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs';
import { DialogService } from './hyraui/dialog/dialog.service';
import { AnalyticsService } from './shared/analytics.service';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

// LogRocket.init('n0lumu/hyra');

@Component({
	selector: 'hy-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	@ViewChild('cookieref') cookieRef!: TemplateRef<any>;

	constructor(
		private http: HttpClient,
		private dialog: DialogService,
		private route: ActivatedRoute,
		private auth: AuthService,
		private analytics: AnalyticsService,
		private router: Router,
	) {}

	ngOnInit() {
		// retrieve the theme from local storage
		const theme = localStorage.getItem('hy-theme');

		// if the theme is dark and path does not contain 'get-started', then toggle the dark class
		if (theme === 'dark' && !window.location.pathname.includes('get-started')) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}

		this.auth.getLoggedInUser().subscribe({
			next: (user) => {
				if (user) {
					this.analytics.boot({
						email: user.email,
						name: user.username,
						user_id: user.id,
						created_at: new Date(user.created_at).getTime() / 1000,
					});

					if (user.theme === 'dark' && !window.location.pathname.includes('get-started')) {
						document.documentElement.classList.add('dark');
						// set local storage theme
						localStorage.setItem('hy-theme', 'dark');
					} else {
						document.documentElement.classList.remove('dark');
						// set local storage theme
						localStorage.setItem('hy-theme', 'light');
					}

					getFingerprint().then(
						(fingerprint) => {
							this.http
								.post<any>(`${environment.apiV2Base}/doll`, undefined, {
									withCredentials: true,
									headers: {
										'x-tb': fingerprint as string,
									},
								})
								.pipe(first())
								.subscribe({
									next: (data) => {
										if (data.message === "So sharp you won't feel a thing") {
											window.location.href = 'https://accounts.hyra.io/login';
										}
									},
								});
						},
						(err) => {
							this.http
								.post<any>(`${environment.apiV2Base}/doll`, undefined, {
									withCredentials: true,
								})
								.pipe(first())
								.subscribe({
									next: (data) => {
										if (data.message === "So sharp you won't feel a thing") {
											window.location.href = 'https://accounts.hyra.io/login';
										}
									},
								});
						},
					);
				} else {
					this.analytics.boot();
				}
			},
		});

		const loadingScreen = document.getElementById('loadingScreen');

		if (loadingScreen) {
			loadingScreen.classList.add('fruit');
			setTimeout(() => {
				loadingScreen.classList.add('opacity-0');
				setTimeout(() => {
					loadingScreen.remove();

					if (localStorage.getItem('cc') !== 'true') {
						this.dialog.open(this.cookieRef, { width: '600px', padding: true, disable_close: true });
					}
				}, 200);
			}, 500);
		}

		const promo = this.route.snapshot.queryParams['promo'];

		if (promo) {
			window.localStorage.setItem('promo', promo);
		}
	}
}
