import packageJson from '../../package.json';
import gitVersion from '../git-version.json';

export const environment = {
  production: true,
  apiBase: window.localStorage.getItem('api_base') || 'https://api-staging.hyra.io',
  apiV2Base: window.localStorage.getItem('api_v2_base') || 'https://api.hyra.io',
  socketApi: `https://io.hyra.io`,
  packageVersion: packageJson.version,
  gitVersion: gitVersion,
};
