import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SupportService {
	openRequestForm = new EventEmitter<void>();

	constructor() {}
}
