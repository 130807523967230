import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RouterModule } from '@angular/router';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { DialogModule } from './hyraui/dialog/dialog.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SupportPopoverComponent } from './support-popover/support-popover.component';
import { FormsModule } from '@angular/forms';
import { DateAgoPipeLocal } from './support-popover/date-ago.pipe';
import { MARKED_OPTIONS, MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AuthInterceptor } from './shared/auth.interceptor';

@NgModule({
	declarations: [AppComponent, CookieConsentComponent, SupportPopoverComponent, DateAgoPipeLocal],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		RouterModule,
		DialogModule,
		HttpClientModule,
		FormsModule,
		MarkdownModule.forRoot({
			markedOptions: {
				provide: MARKED_OPTIONS,
				useFactory: markedOptionsFactory,
			},
		}),
	],
	exports: [],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

// Factory function to customize marked options
export function markedOptionsFactory(): MarkedOptions {
	const renderer = new MarkedRenderer();

	const ignore = (text: string) => text;

	renderer.html = ignore;
	renderer.table = ignore;
	renderer.tablecell = ignore;
	renderer.tablerow = ignore;

	renderer.image = ignore;
	renderer.del = ignore;

	// Customize only the bold syntax
	renderer.strong = (text: string) => `<strong>${text}</strong>`;

	renderer.link = (href: string, title: string, text: string) => {
		if (href.startsWith('http')) {
			return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
		} else {
			return `<a href="${href}">${text}</a>`;
		}
	};

	return {
		renderer: renderer,
		// You can also set other options as needed
	};
}
