import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { first, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class NexusGuard  {
	constructor(
		private auth: AuthService,
		private ngzone: NgZone,
		private router: Router,
		private http: HttpClient,
		private analytics: AnalyticsService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
		return this.auth.isAuthenticated().then((authenticated) => {
			if (authenticated) {
				return this.http
					.get<any>(`${environment.apiBase}/workspaces/nexus`, {
						withCredentials: true,
					})
					.pipe(first())
					.toPromise()
					.then((data) => {
						if (data.id) {
							this.ngzone.run(() => this.router.navigate(['/' + data.id]));
							this.analytics.workspace(data.id, {
								id: data.id,
								name: data.name,
							});
							return true;
						} else {
							this.ngzone.run(() => this.router.navigate(['/get-started/start']));
							return true;
						}
					})
					.catch((err) => {
						this.ngzone.run(() => {
							if (environment.production) {
								// 					this.router.navigate(['/auth/login'], { queryParams: { redirectTo: state.url } }),
								window.location.href = `https://accounts.hyra.io/login`;
							} else if (!environment.production) {
								window.location.href = `http://localhost:3000/login`;
							}
						});
						return false;
					});
			} else {
				this.ngzone.run(() => {
					if (environment.production) {
						// 					this.router.navigate(['/auth/login'], { queryParams: { redirectTo: state.url } }),
						window.location.href = `https://accounts.hyra.io/login${ state.url ? `?redirectTo=${state.url}` : '' }`;
					} else if (!environment.production) {
						window.location.href = `http://localhost:3000/login${ state.url ? `?redirectTo=${state.url}` : '' }`;
					}
				});
				return false;
			}
		}) as Promise<boolean>;
	}
}
