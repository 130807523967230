import { Component, NgZone, Renderer2 } from '@angular/core';
import { AuthService, User } from '../shared/auth.service';
import { Subject, first, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';
import { OtherUplod } from '../players/bans/ban-view/create/create.component';

import {
	trigger,
	state,
	style,
	animate,
	transition,
	// ...
} from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SupportService } from './support.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'hy-support-popover',
	templateUrl: './support-popover.component.html',
	styleUrls: ['./support-popover.component.scss'],
	animations: [
		trigger('openClose', [
			transition(':enter', [
				// Start with the element scaled down and fully transparent
				style({
					opacity: 0,
					transform: 'scale(0.95)',
					'transform-origin': 'bottom right', // Set the origin of transformation
				}),
				// Animate to the final state: fully opaque and scaled to 100%
				animate('0.1s', style({ opacity: 1, transform: 'scale(1)' })),
			]),
			transition(':leave', [
				// Begin the leave transition from the current state to scaled down and transparent
				style({
					'transform-origin': 'bottom right', // Ensure the transform origin is consistent
				}),
				// Animate to the final state of the leave transition
				animate('0.1s', style({ opacity: 0, transform: 'scale(0.95)' })),
			]),
		]),
	],
})
export class SupportPopoverComponent {
	expandedPopover = false;
	popoverOpen = false;

	uploadedFiles: OtherUplod[] = [];
	uploadedFilesRaw: File[] = [];

	user?: User;

	raisingTicket = false;

	done = false;

	$onDestroy = new Subject<void>();

	version?: string;
	versionLastUpdated = new Date();

	versionCopied = false;

	show_w4r6Qo = false;

	constructor(
		private auth: AuthService,
		private http: HttpClient,
		private support: SupportService,
		private clipboard: Clipboard,
		private renderer: Renderer2,
		private ngZone: NgZone,
	) {
		this.auth.getLoggedInUser().subscribe((user: User | undefined) => {
			this.user = user;
		});

		this.support.openRequestForm.pipe(takeUntil(this.$onDestroy)).subscribe(() => {
			this.popoverOpen = true;
			this.expandedPopover = true;
		});

		this.version = environment.packageVersion + '/' + environment.gitVersion.lastCommitNumber;
		this.versionLastUpdated = new Date(environment.gitVersion.lastCommitTime);

		this.show_w4r6Qo = window.localStorage.getItem('w4r7Qo-hide') !== 'true';
	}

	copyVersion() {
		if (this.version) {
			this.clipboard.copy('Hyra ' + this.version);

			this.versionCopied = true;

			setTimeout(() => {
				this.versionCopied = false;
			}, 1500);
		}
	}

	ngOnDestroy() {
		this.$onDestroy.next();
		this.$onDestroy.complete();
	}

	submitSupportRequest(form: NgForm) {
		this.raisingTicket = true;
		const formData = new FormData();
		// add raw files to form
		formData.append('message', form.value.message);

		this.uploadedFilesRaw.forEach((file) => {
			formData.append('files', file);
		});

		if (!this.user) {
			formData.append('email', form.value.email);
			formData.append('username', form.value.username);
		}

		this.http
			.post<any>(
				`${environment.apiV2Base}/support/${this.user ? 'raise-ticket' : 'raise-anon-ticket'}`,
				formData,
				{
					withCredentials: true,
				},
			)
			.pipe(first())
			.subscribe({
				next: (res) => {
					this.raisingTicket = false;
					form.reset();
					this.expandedPopover = false;
					this.done = true;
					this.uploadedFiles = [];
					this.uploadedFilesRaw = [];

					setTimeout(() => {
						if (this.done) {
							this.done = false;
							this.popoverOpen = false;
						}
					}, 3000);
				},
				error: (err) => {
					this.raisingTicket = false;
					window.alert(err.error.message || 'An error occurred while submitting your ticket.');
				},
			});
	}

	onFileSelected(event: any) {
		const file: File = event.target.files[0];

		if (file) {
			if (this.uploadedFiles.length >= 10) return window.alert('You can only upload 10 files at a time.');

			const reader = new FileReader();

			reader.onload = (e: any) => {
				const base64 = e.target.result;

				const otherUpload: OtherUplod = {
					name: file.name,
					base64: base64,
					type: file.type,
					enum: 1,
				};

				this.uploadedFiles.push(otherUpload);
				this.uploadedFilesRaw.push(file);
			};

			reader.readAsDataURL(file);
		}
	}

	handlePaste(event: any) {
		const items = (event.clipboardData || event.originalEvent.clipboardData).items;
		let blob = null;

		for (const item of items) {
			if (item.type.indexOf('image') === 0) {
				blob = item.getAsFile();
			}
		}

		// load image if there is a pasted image
		if (blob !== null) {
			if (this.uploadedFiles.length >= 10) return window.alert('You can only upload 10 files at a time.');

			const reader = new FileReader();
			reader.onload = (evt: any) => {
				const b64 = evt.target.result;

				const fileExtension = b64.split(';')[0].split('/')[1];

				// convert the base64 to a file
				const file = this.dataURItoFile(b64, 'unknown' + '.' + fileExtension);

				const otherUpload: OtherUplod = {
					name: file.name,
					base64: b64,
					type: file.type,
					enum: 1,
				};

				this.uploadedFiles.push(otherUpload);
				this.uploadedFilesRaw.push(file);
			};
			reader.readAsDataURL(blob);
		}
	}

	removeFile(index: number) {
		this.uploadedFiles.splice(index, 1);
		this.uploadedFilesRaw.splice(index, 1);
	}

	dataURItoFile(dataURI: string, filename: string) {
		const arr = dataURI.split(',');
		const mime = arr[0].match(/:(.*?);/)![1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	hideApplication() {
		window.localStorage.setItem('w4r7Qo-hide', 'true');
		this.show_w4r6Qo = false;
	}

	openApplication() {
		const script = this.renderer.createElement('script');
		script.type = 'text/javascript';
		const popupOptions = {
			emoji: {
				text: '👋',
				animation: 'wave',
			},
      customFormUrl: 'https://tal-orcl.hyra.io/cso54',
			layout: 'modal',
			width: 800,
			hiddenFields: {
				'hyx-email': this.user?.email,
				'hyx-username': this.user?.username,
			},
		};
		script.text = `Tally.openPopup('m6xl5N', ${JSON.stringify(popupOptions)});`;
		// append to head of page
		this.renderer.appendChild(document.head, script);
	}
}
