import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { first } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './auth.service';

declare var profitwell: any;

interface UserData {
	email: string;
	name: string;
	created_at: number;
	user_id: string;
}

interface CompanyInformation {
	id: string;
	name: string;
}

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	constructor(private http: HttpClient) {}

	track(name: string, props?: {}) {
		//Intercom('trackEvent', name, props);
		posthog.capture(name, props);
	}

	boot(user?: UserData) {
		if (user) {
			posthog.init('phc_KO2l2DQ3S0tn9O2OTEmyApXOJHqT2E915eXCtgqpX1b', {
				api_host: 'https://t.hyra.io',
				person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
				loaded: (posthog) => {
					posthog.identify(user.user_id, { email: user.email, username: user.name, user_id: user.user_id });
				},
				opt_in_site_apps: true,
			});

			profitwell('start', { user_email: user.email });
		} else {
			posthog.init('phc_lRutcp7HNYOhbLiXhhG0Ir4qW29QBQKtcSPxpkua5c0', {
				api_host: 'https://science.hyra.io',
				opt_in_site_apps: true,
			});
		}
	}

	workspace(id: string, company?: CompanyInformation) {
		if (company) {
			posthog.group('workspace', 'id:' + id, {
				name: company.name,
				id: company.id,
			});
		} else {
			posthog.group('workspace', 'id:' + id);
		}
	}

	checkFeature(flag: string) {
		return posthog.isFeatureEnabled(flag) || false;
	}

	checkAB(flag: string) {
		return posthog.getFeatureFlag(flag);
	}

	shutdown() {
		//Intercom('shutdown');
		posthog.reset();
		// mixpanel.reset();
	}

	login(user: User, redirect?: string) {
		posthog.identify(user.id, { email: user.email, username: user.username });

		this.track('Logged in', {
			redirect: redirect || 'None',
		});
	}
}
