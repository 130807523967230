<div class="fixed z-50 hidden md:flex flex-col items-end bottom-4 right-4">
  <div *ngIf="popoverOpen" [@openClose] class="dropdown mb-2 transition-all duration-300"
    [ngClass]="{ 'w-[260px] max-h-[4000px]': !expandedPopover, 'w-[330px] max-h-[50000px]': expandedPopover}">
    <ng-container *ngIf="!expandedPopover && !done">
      <div class="flex flex-col p-2">
        <a href="https://help.hyra.io" target="_blank" class="dropdown-item"><i
            class="fa-regular fa-book-open mr-4"></i>Help & Documentation</a>
        <a class="dropdown-item" (click)="expandedPopover = true"><i class="fa-regular fa-headset mr-4"></i>Get in
          touch</a>
        <a href="https://discord.gg/9P9nW948Fh" target="_blank" class="dropdown-item"><i
            class="fa-brands fa-discord mr-4"></i>Join our Discord</a>

        <hr class="static block border-black/10 dark:border-white/10 my-2 ">

        <a href="https://feedback.hyra.io/changelog" target="_blank" class="dropdown-item"><i
            class="fa-regular fa-code-commit mr-4"></i>What's new</a>
        <a href="https://docs.hyra.io/api-reference/introduction" target="_blank" class="dropdown-item"><i
            class="fa-regular fa-code mr-4"></i>API Documentation</a>

        <hr class="static block border-black/10 dark:border-white/10 my-2 ">

        <a href="https://policies.hyra.io/privacy" target="_blank"
          class="dropdown-item text-gray-600 dark:text-gray-400">Privacy</a>
        <a href="https://policies.hyra.io/terms" target="_blank"
          class="dropdown-item text-gray-600 dark:text-gray-400">Terms</a>


        <hr class="static block border-black/10 dark:border-white/10 my-2 ">

        <div (click)="copyVersion()" *ngIf="version && versionLastUpdated">
          <div class="text-xs text-gray-600 dark:text-gray-400">Hyra {{ version }} {{ versionCopied ? ' - Copied!' : ''
            }}</div>
          <div class="text-xs text-gray-600 dark:text-gray-400">Updated {{ versionLastUpdated | dateAgoLocal }}</div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="expandedPopover && !done">
      <div class="p-2">
        <h3 class="text-lg font-semibold mb-1">Contact Hyra</h3>

        <form (ngSubmit)="submitSupportRequest(supportform)" #supportform="ngForm">
          <div *ngIf="!user" class="mb-4">
            <label for="username" class="label">Your Roblox username</label>
            <input [ngModel] type="text" required name="username" id="username" minlength="3" maxlength="250"
              placeholder="e.g. johnsmith" class="input">
          </div>

          <div *ngIf="!user" class="mb-4">
            <label for="email" class="label">Your email</label>
            <input [ngModel] type="email" required id="email" name=email maxlength="150" minlength="3"
              placeholder="e.g. john.smith@hyra.io" class="input">
          </div>

          <div class="mb-1">
            <label for="message" class="label">Your message</label>
            <textarea (paste)="handlePaste($event)" maxlength="5000" required [ngModel] id="message" name="message"
              class="textarea" rows="4" placeholder="Hi there, I need help with..."></textarea>
          </div>

          <div class="mb-4">
            <input (change)="onFileSelected($event)" type="file" #fileUpload class="hidden">
            <a (click)="fileUpload.click();" class="block cursor-pointer"><i class="fa-regular fa-paperclip"></i> Attach
              Files</a>

            <div *ngFor="let file of uploadedFiles; let index = index;"
              class="flex items-center gap-2 py-1 px-2 hover:bg-gray-150 dark:hover:bg-gray-600/60 rounded">
              <a (click)="removeFile(index)"
                class="hover:text-red-500 dark:hover:text-red-500 cursor-pointer text-gray-700 dark:text-gray-300"><i
                  class="fa-regular fa-xmark"></i></a>
              <div>{{ file.name }}</div>
            </div>
          </div>

          <button [disabled]="!supportform.valid" class="brand-button w-full"
            [class.btn-loading]="raisingTicket">Send</button>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="done">
      <div class="p-6 text-center">
        <i class="fa-regular fa-check-circle text-green-500 text-4xl mb-4"></i>

        <h3 class="text-lg font-semibold mb-3">We're on it!</h3>

        <p class="text-sm">We'll get back to you as soon as possible. You'll get replies to your email.</p>
      </div>
    </ng-container>
  </div>

  <div class="flex items-center gap-2">
    <!-- <a *ngIf="show_w4r6Qo" (click)="openApplication()"
      class="cursor-pointer bg-gradient-to-br from-teal-500 to-indigo-400 dark:from-teal-600 dark:to-indigo-800 rounded-full text-white border-black/5 dark:border-white/5 group flex items-center gap-2 shadow py-1 px-3">
      <img src="https://hyraedge.b-cdn.net/_layout/Shards.svg" alt="Hyra Logo" class="h-4 aspect-square">
      <div class="block group-hover:hidden">We're hiring!</div>
      <div class="hidden group-hover:block">Click to Apply!</div>
      <a (click)="hideApplication()"
        class="text-gray-200 hover:text-red-300 dark:hover:text-red-500 dark:text-gray-300 hidden group-hover:block"><i
          class="fa-regular fa-xmark"></i></a>
    </a> -->

    <a (click)="popoverOpen = !popoverOpen; expandedPopover = false; done = false;"
      class="h-9 w-9 inline-grid place-content-center bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer rounded-full border border-black/5 dark:border-white/5 shadow">
      <i class="fa-regular fa-question" *ngIf="!popoverOpen"></i>
      <i class="fa-regular fa-xmark" *ngIf="popoverOpen"></i>
    </a>
  </div>
</div>
