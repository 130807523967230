import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { NexusGuard } from './shared/nexus.guard';

const routes: Routes = [
  { path: "get-started", loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: "get-started2", loadChildren: () => import('./onboarding-v2/onboarding-v2.module').then(m => m.OnboardingV2Module) },
  { path: "auth", loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: "reflect", loadChildren: () => import('./reflect/reflect.module').then(m => m.ReflectModule) },
  { path: "", loadChildren: () => import('./workspaces/workspaces.module').then(m => m.WorkspacesModule), canActivate: [NexusGuard] },
  { path: ":id", loadChildren: () => import('./workspaces/workspaces.module').then(m => m.WorkspacesModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
