import { Component, OnInit } from '@angular/core';
import { DialogService } from '../hyraui/dialog/dialog.service';

@Component({
	selector: 'hy-cookie-consent',
	templateUrl: './cookie-consent.component.html',
	styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
	constructor(private dialog: DialogService) {}

	ngOnInit(): void {}

	agreeToCookiePolicy() {
		// set cc cookie

		window.localStorage.setItem('cc', 'true');
    this.dialog.close();
	}
}
