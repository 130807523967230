import { EventEmitter, Injectable, TemplateRef } from '@angular/core';

export interface DialogMeta {
	template?: TemplateRef<any>;
	open: boolean;
	padding?: boolean;
	scaler?: boolean;
	width?: string;
	disable_close?: boolean;
}

interface DialogConfig {
	padding: boolean;
	scaler?: boolean;
	width: string;
	disable_close?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	dialog_open: boolean = false;

	// dialog opened eventemitter
	dialogOpened = new EventEmitter<DialogMeta>();

	constructor() {}

	open(template: TemplateRef<any>, config: DialogConfig) {
		this.dialog_open = true;
		this.dialogOpened.emit({
			open: true,
			template: template,
			padding: config.padding,
			scaler: config.scaler || false,
			width: config.width,
			disable_close: config.disable_close || false,
		});
	}

	close() {
		this.dialog_open = false;
		this.dialogOpened.emit({
			open: false,
		});
	}
}
