import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, first, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './analytics.service';

export interface User {
	id: string;
	theme: 'light' | 'dark';
	type: 'user' | 'application';
	username: string;
	has_password: boolean;
	has_enabled_2fa: boolean;
	has_completeD_2fa: boolean;
	email_verified: boolean;
	email: string;
	display_name: string;
	created_at: string;
	avatar: {
		colour: string;
		initial: string;
		transparent: string;
	};
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private http: HttpClient,
		private router: Router,
		private ngzone: NgZone,
		private analytics: AnalyticsService,
	) {}

	loggedInUser: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

	getLoggedInUser(): Observable<User | undefined> {
		if (!this.loggedInUser.value) {
			return this.http
				.get<User>(`${environment.apiV2Base}/users/@me`, {
					withCredentials: true,
				})
				.pipe(
					tap((user) => this.loggedInUser.next(user)), // Cache the user data
					catchError(() => {
						this.loggedInUser.next(undefined);
						return this.loggedInUser.asObservable();
					}),
				);
		} else {
			// If the user data is already cached, return it as an Observable
			return this.loggedInUser.asObservable();
		}
	}

	isAuthenticated() {
		return new Promise<boolean>((resolve, reject) => {
			this.getLoggedInUser().subscribe({
				next: (user) => {
					if (user) {
						return resolve(true);
					} else {
						return resolve(false);
					}
				},
			});
		});
	}

	logout() {
		return new Promise((resolve, reject) => {
			this.http
				.post(
					`${environment.apiV2Base}/users/session/logout`,
					{},
					{
						withCredentials: true,
					},
				)
				.pipe(first())
				.subscribe({
					next: () => {
						setTimeout(() => {
							this.ngzone.run(() => {
								if (environment.production) {
									// 					this.router.navigate(['/auth/login'], { queryParams: { redirectTo: state.url } }),
									window.location.href = `https://accounts.hyra.io/login`;
								} else if (!environment.production) {
									window.location.href = `http://localhost:3000/login`;
								}
							});
						}, 1000);
						this.analytics.shutdown();
						return resolve(true);
					},
				});
		});
	}
}
