import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private auth: AuthService,
		private ngzone: NgZone,
		private router: Router,
		private route: ActivatedRoute,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
		return this.auth.isAuthenticated().then((authenticated) => {
			if (authenticated) {
				if (this.route.snapshot.queryParams['redirectTo']?.includes('http')) {
					window.location.href = this.route.snapshot.queryParams['redirectTo'];
				} else if (
					this.route.snapshot.queryParams['redirectTo'] &&
					this.route.snapshot.queryParams['redirectTo'] != '/'
				) {
					this.router.navigate([this.route.snapshot.queryParams['redirectTo']]);
				}
				return true;
			} else {
				this.ngzone.run(() => {
					if (environment.production) {
						// 					this.router.navigate(['/auth/login'], { queryParams: { redirectTo: state.url } }),
						window.location.href = `https://accounts.hyra.io/login${
							state.url ? `?redirectTo=${state.url}` : ''
						}`;
					} else if (!environment.production) {
						window.location.href = `http://localhost:3000/login${
							state.url ? `?redirectTo=${state.url}` : ''
						}`;
					}
				});
				return false;
			}
		});
	}
}
