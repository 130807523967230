import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogMeta, DialogService } from '../dialog.service';

import {
	trigger,
	style,
	animate,
	transition,
	// ...
} from '@angular/animations';

@Component({
	selector: 'hy-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
	animations: [
		trigger('openClose', [
			transition(':enter', [
				style({
					transform: 'scale(0.8)',
					opacity: 0,
				}),
				animate('0.1s', style({ opacity: 1, transform: 'scale(1)' })),
			]),
			transition(':leave', [animate('0.1s', style({ opacity: 0, transform: 'scale(0.8)' }))]),
		]),
	],
})
export class DialogComponent implements OnInit {
	opened: boolean = false;
	template?: TemplateRef<any>;
	padding: boolean = true;
	scaler = false;
	width?: string;
	disable_close: boolean = false;
	parent_open = false;

	@ViewChild('dialogMain') dialogMain?: ElementRef;

	constructor(public dialog: DialogService) {
		this.dialog.dialogOpened.subscribe((data) => {
			if (this.opened && !data.open) {
				this.updateState(data);
				setTimeout(() => {
					this.parent_open = false;
				}, 130);
			} else {
				if (data.open) {
					this.parent_open = true;
				}
				this.updateState(data);
			}
		});
	}

	updateState(data: DialogMeta) {
		this.opened = data.open;
		this.template = data.template;
		this.padding = typeof data.padding === 'boolean' ? data.padding : true;
		this.scaler = typeof data.scaler === 'boolean' ? data.scaler : false;
		this.width = data.width;
		this.disable_close = data.disable_close || false;
	}

	ngOnInit(): void {}

	@HostListener('document:keydown.escape', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (this.opened && !this.disable_close) {
			this.dialog.close();
		}
	}

	@HostListener('click', ['$event'])
	handleClick(event: MouseEvent) {
		if (this.opened && !this.disable_close) {
			if ((event.target as Node) === this.dialogMain?.nativeElement) {
				this.dialog.close();
			}
		}
	}
}
