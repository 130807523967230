import {
	HttpClient,
	HttpErrorResponse,
	HttpHandler,
	HttpInterceptor,
	HttpInterceptorFn,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { catchError, first, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private http: HttpClient, private ngzone: NgZone) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		// send the request and if it returns 401 then we will refresh the token
		if (req.headers.get('isRetryRequest') || req.url.endsWith('/users/session/refresh')) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					// refresh token http request

					return this.http
						.post(
							`${environment.apiV2Base}/users/session/refresh`,
							{},
							{ withCredentials: true, headers: { isRetryRequest: 'true' } },
						)
						.pipe(
							switchMap((response) => {
								console.log('refresh token response', response);
								// Retry the original request after token refresh
								return next.handle(req);
							}),
							catchError((refreshError) => {
								// Handle token refresh error
								console.log('refresh token error', refreshError);
								this.ngzone.run(() => {
									if (refreshError.error.message === 'Token is blacklisted' && refreshError.error.message !== "No refresh token") {
										if (environment.production) {
											// 					this.router.navigate(['/auth/login'], { queryParams: { redirectTo: state.url } }),
											window.location.href = `https://accounts.hyra.io/login`;
										} else if (!environment.production) {
											window.location.href = `http://localhost:3000/login`;
										}
									}
								});
								return throwError(refreshError);
							}),
						);
				}
				return throwError(error);
			}),
		);
	}
}
